
import { Component, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { ImplementerProfileModel } from '@/store/modules/implementer.module'
import { CityModel } from '@/core/models/CityModel'

import ImplementerProfileDescriptionForm from './ImplementerProfileDescriptionForm.vue'
import ImplementerProfileServicesForm from './ImplementerProfileServicesForm.vue'
import ImplementerProfileWorksForm from './ImplementerProfileWorksForm.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'

import { BreakpointsMixin } from '@/mixins/breakpoints.mixin'

const ImplementerStore = namespace('ImplementerStore')
const CitiesStore = namespace('CitiesStore')

@Component({
  name: 'ImplementerProfileForm',
  components: {
    ImplementerProfileDescriptionForm,
    ImplementerProfileServicesForm,
    ImplementerProfileWorksForm,
    BaseButton,
    BaseIcon,
  },
})
export default class ImplementerProfileForm extends BreakpointsMixin {
  @Prop()
  readonly implementerData: any

  @CitiesStore.Getter
  public cityList!: CityModel[]

  @ImplementerStore.State('implementerProfileModel')
  implementerProfileModel!: ImplementerProfileModel

  @ImplementerStore.Mutation('setImplementerProfileModelByComponent')
  setImplementerProfileModelByComponent!: (
    model: ImplementerProfileModel
  ) => void

  @CitiesStore.Action
  private getCities!: () => Promise<CityModel[]>

  public async created(): void {
    await this.getCities()
  }

  update<T>(data: T, currentComponent: string): void {
    this.setImplementerProfileModelByComponent(
      {
        ...this.implementerProfileModel[currentComponent],
        [currentComponent]: data,
      },
      currentComponent
    )
  }
}
